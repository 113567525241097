.btn-primary {
    height: 44px;
    background-color: var(--form-button-color) !important;
    border-color: var(--form-button-color) !important;
    color: var(--form-button-text-color) !important;
}

.col-form-label {
    font-size: 13px;
    line-height: 150%;
    color: var(--label-color);
    padding-bottom: 4px;
}


.form-control {
    border-color: var(--input-border-color);
    background-color: var(--input-bg);
    border-radius: var(--input-border-radius);
    color: var(--form-text-color);

    &:active,
    &:focus:not(.is-invalid) {
        box-shadow: 0px 0px 4px rgba(10, 101, 237, 0.4);
        border-color: $input-focus-border-color;
    }

    &.is-invalid {
        &:focus {
            box-shadow: 0px 0px 4px opacify($color: $red, $amount: 0.3);
        }
    }

    &[disabled],
    &:disabled {
        background-color: $input-disabled-bg;
        border-color: $input-disabled-bg;
        padding: 0;
        height: 20px;
        +.input-group-append{
            background-color: $input-disabled-bg;
            border-color: $input-disabled-bg;
            border-radius: 0 $input-border-radius $input-border-radius 0;

            .input-group-text{
                background-color: transparent;
                border: none;
                display: none;
            }
        }

        &:active{
            box-shadow: none;
        }
    }
}


.form-check-label {
    padding-left: 30px;

    .form-check-input {
        position: relative;
        width: 0;
        height: 0;
        opacity: 0;

        + span {
            font-size: 16px;
            line-height: 1.5;
            color: $input-check-label-color;

            &::before {
                content: '';
                display: inline-block;
                width: 26px;
                height: 26px;
                position: absolute;
                background-color: $input-bg;
                border-radius: $border-radius;
                border: $input-border-width solid $input-border-color;
                left: 0;
                top: 0;
            }
        }

        &:checked {
            + span {
                color: $black;
                &::before {
                    background-color: var(--form-button-color);
                    border-color: var(--form-button-color);
                }

                &::after {
                    position: absolute;
                    content: '';
                    display: inline-block;
                    left: 4px;
                    top: 3px;
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-color: transparent;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' fill='none'%3E%3Cpath d='M1 7.36842L4.85185 12L14 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                }
            }
        }
    }

    &:hover {
        .form-check-input:not(:disabled) {
            + span {
                &::before {
                    border: $input-border-width solid $input-border-color-hover;
                }
            }
        }
    }
}


.card {
    padding: 0;
    &.bg-light {
        background-color: transparent !important;
    }
}

.js-image-zoom {
    span {
        margin-bottom: 10px;
    }
}

.text-align-right {
    text-align: right;
}

.text-align-right-input {
    input {
        text-align: right;
    }
}

.text-bold {
    font-weight: 600;
}

.text-bold-input {
    input {
        font-weight: 600;
    }
}

.table-bordered {
    border: none !important;
}

.flatpickr-day.selected {
    background-color: #99999a !important;
    border-color: #99999a !important;
}

.form-control:focus {
    background-color: var(--input-bg);
    color: var(--form-text-color);
}

.font-size-10 {
    font-size: 10px;
}

.iti {
    width: 100%;
}

.btn-primary:not([disabled]):hover {
    opacity: 0.8;
    cursor: pointer;
}

.iti__flag-container {
    pointer-events: none;

    .iti__arrow {
        display: none;
    }
}
